﻿.win-listview.win-control-fill,
.win-section.win-control-fill,
.win-pivot.win-control-fill {
    height: 100%;
}

button.win-control-fill,
input.win-control-fill,
textarea.win-control-fill,
select.win-control-fill,
table.win-control-fill,
.win-searchbox.win-control-fill,
.win-datepicker.win-control-fill {
    width: 100%;
    min-width: 1px;
    max-width: none;
}




// Hub overrided values
.win-hub-vertical .win-hub-section {
    width: 100%;
    padding: 0;
}

.win-hub-vertical .win-hub-surface {
    width: 100%;
    padding: 0;
}

.win-hub-vertical .win-hub-section-header {
    padding-left: 10px;
}

h1.win-hub-section-header-content,
h2.win-hub-section-header-content,
h3.win-hub-section-header-content,
h4.win-hub-section-header-content,
h5.win-hub-section-header-content {
    margin: 0;
    padding: 0;
}


// ( mainly listview ) overrided values
html.win-hoverable .win-listview .win-itembox:hover::before,
html.win-hoverable .win-itemcontainer .win-.itembox:hover::before {
    width: 100%;
    height: 100%;
}


.win-selectionstylefilled.win-listview .win-selected .win-selectionbackground,
.win-selected.win-selectionstylefilled.win-itemcontainer .win-selectionbackground {
    background-color: rgba(0,0,0,.6) !important;
}


.win-listview .win-uniformlistlayout .win-container {
    margin: 10px 0px 0px 0px !important;
}

.win-listview .win-uniformgridlayout .win-container {
    margin: 10px 10px 0px 0px !important;
}

.win-listview > .win-viewport.win-vertical .win-uniformgridlayout {
    height: 100% !important;
}


// dropdown overrided values
.win-datepicker select {
    min-width: 0 !important;
    max-width: none;
    width: 100%;
}

.win-dropdown {
    max-width: none;
}

// table overrided values
.win-table {
    border-collapse: collapse;
    width: 400px;


    th, td {
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even){

        background-color: #f2f2f2
    }

}

